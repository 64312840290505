<template>

    

    <div style="padding:20px;">

        <v-card class="" elevation="" max-width="" style="margin-top:20px;" >
            <v-card-text>
                <div style="">
                    <h1 >Treinamentos</h1>
                </div>
            </v-card-text>               
        </v-card>


        <v-card class="" elevation="" max-width="" style="margin-top:20px;" >
            <v-card-text>

                <div style="">
                    <h1 >Cardapio</h1>
                </div>
                <div style="margin-top:20px;margin-left:30px;">
                    <p style="">
                        <b>Garçom Padrão:</b>
                        <a target="_blank" href="https://esvideos.economysoftware.com.br/videos/tutoriais/cardapio/cardapio-garcom-padrao.mp4"><v-icon color="green">mdi-new-box</v-icon></a>
                    </p>
                </div>
                <div style="margin-top:5px;margin-left:30px;">
                    <p style="">
                        <b>Praça X PDV:</b>Funcionalidade nova
                        <a target="_blank" href="https://esvideos.economysoftware.com.br/videos/tutoriais/cardapio/cardapio-configuracao-pracaXpdv.mp4"><v-icon color="green">mdi-new-box</v-icon></a>
                    </p>
                </div>

            </v-card-text>               
        </v-card>


        <v-card class="" elevation="" max-width="" style="margin-top:20px;" >
            <v-card-text>

                <div style="">
                    <h1 >Espot</h1>
                </div>
                <div style="margin-top:20px;margin-left:30px;">
                    <p style="">
                        <b>Treinamento de Utilização:</b>Voltado para a Equipe de Recepção
                        <a target="_blank" href="/Apresentacao?sistema=espot"><v-icon color="blue">mdi-youtube-tv</v-icon></a>
                    </p>
                </div>

            </v-card-text>               
        </v-card>

        <v-card class="" elevation="" max-width="" style="margin-top:20px;" >
            <v-card-text>

                <div style="">
                    <h1 >FD Guest - Concierge</h1>
                </div>
                <div style="margin-top:20px;margin-left:30px;">
                    <p style="">
                        <b>Sistema de Avaliações:</b>Voltado para todos os setores
                        <a target="_blank" href="/Apresentacao?sistema=fdguest-avaliacoes"><v-icon color="blue">mdi-youtube-tv</v-icon></a>
                    </p>
                </div>

                <div style="margin-top:5px;margin-left:30px;">
                    <p style="">
                        <b>Sistema de Solicitações:</b>Voltado para todos os setores
                        <a target="_blank" href="/Apresentacao?sistema=fdguest-solicitacoes"><v-icon color="blue">mdi-youtube-tv</v-icon></a>
                    </p>
                </div>

                <div style="margin-top:5px;margin-left:30px;">
                    <p style="">
                        <b>Camareiras:</b>Voltado para a de Governança
                        <a target="_blank" href="/Apresentacao?sistema=fdguest-camareiras"><v-icon color="blue">mdi-youtube-tv</v-icon></a>
                    </p>
                </div>
                <div style="margin-top:5px;margin-left:30px;">
                    <p style="">
                        <b>Restaurante</b>Voltado para a Equipe de A&B
                        <a target="_blank" href="/Apresentacao?sistema=fdguest-restaurante"><v-icon color="blue">mdi-youtube-tv</v-icon></a>
                    </p>
                </div>
                

            </v-card-text>               
        </v-card>

        <v-card class="" elevation="" max-width="" style="margin-top:20px;" >
            <v-card-text>

                <div style="">
                    <h1 >FD Guest - Manutenção</h1>
                </div>
                <div style="margin-top:20px;margin-left:30px;">
                    <p style="">
                        <b>Treinamento de Utilização:</b>Voltado para a Equipe de Manutenção
                        <a target="_blank" href="/Apresentacao?sistema=fdguest-manutencao"><v-icon color="blue">mdi-youtube-tv</v-icon></a>
                    </p>
                </div>

            </v-card-text>               
        </v-card>



    </div> 

    
</template>

<script>

export default {
    name:'Robos',
    components: {
    },
    data(){
        return{
            
        }
    },
    watch: {
        
    },
    methods:{
            
    },

    
    async mounted(){

    }
    
}
</script>

<style>
    .linhaFormulario{
        display: flex;
        flex-direction: row;
        /* background-color:red; */
        padding-left: 10%;
        padding-right: 10%;
    }

    .celularCampoForm{
        width:25%;
        padding:10px;
        /* background-color: green; */
    }

    .theme--light.v-input--is-disabled input, .theme--light.v-input--is-disabled textarea {
        color: rgba(0, 0, 0);
    }

</style>